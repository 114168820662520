import React, { useState,useEffect,useRef  } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Helmet } from 'react-helmet';

import './css/styles.css';
import './css/vendor.css';
import './css/custom.css';

import helpers from "./components/_helpers/strapi_functions";

import Template from './components/template/template';
import Homepage from "./components/homepage/homepage";
import Post from "./components/posts/post_standard";
import Category from "./components/category/category";
import About from "./components/about/about";




function App() {

  const [email, setUserEmail] = useState("");

  var contextValue = {
    
  }

  useEffect(() => {
      
  }, []);
  

  return (<>
    <Helmet>
        <title>MixMyNews - Something new for you</title>
        <meta name="description" content="This is a description of my page" />
        <meta name="keywords" content="react, meta tags, seo" />
    </Helmet>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Template/>}>
          <Route index element={<Homepage />} /> 
          <Route path="/post/:id/:article" element={<Post />} />
          <Route path="/category/:id/:nome" element={<Category />} />
          <Route path="/about" element={<About />} />
        </Route>
      </Routes>
    </BrowserRouter>

    </>
  );
}

export default App;
