import { useEffect, useState } from "react";
import { Link,useParams,useHistory  } from "react-router-dom";


const Post = (props) => {

 

    useEffect(() => {

    
    }, []);
    
    return (<div>
     
      {props.attributes ? (
        <div>
        
        <article className=" format-standard">
          <div className="entry__thumb">
            <a href={"/post/"+props.id+"/"+props.attributes.serp} className="thumb-link">
              {props.attributes.Immagine.data && (
                 <img
                src={"https://contents.mixmynews.com"+props.attributes.Immagine.data.attributes.url}
                srcSet={"https://contents.mixmynews.com"+props.attributes.Immagine.data.attributes.url}
                alt=""
              />
              )}
             
            </a>
          </div>{" "}
          {/* end entry__thumb */}
          <div className="entry__text">
            <div className="entry__header">
              <div className="entry__meta">
                <span className="entry__cat-links">
                { props.attributes.categoria.data && 
                        (
                          props.attributes.categoria.data.map(el=>(
                            <a key={el.id} href="#">{el.attributes.Nome}</a>
                                
                            ))
                        ) 
                    }
                  

                </span>
              </div>
              <h1 className="entry__title">
                <a href={"/post/"+props.id+"/"+props.attributes.serp}>{props.attributes.Titolo}</a>
              </h1>
            </div>
            <div className="entry__excerpt">
              <p>
                {props.attributes.Abstract[0].children[0].text}
              </p>
            </div>
          </div>{" "}
          {/* end entry__text */}
        </article>{" "}
        {/* end entry */}
      </div>
      ) : (<div></div>)}
        
        </div>
      );
    };
    
export default Post;