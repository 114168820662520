import { useEffect, useState } from "react";
import { Link,useParams,useHistory  } from "react-router-dom";
import about from '../../images/about.jpg'

const Post = (props) => {

     

    useEffect(() => {
       
      }, []);
console.log(props)
    return (
        <>
        <section class="s-content">
        <div class="row">
            <div class="column large-12">

                <section>

                    <div class="s-content__media">
                        <img src={about} alt=""/>
                    </div>

                    <div class="s-content__primary">

                        <h1 class="s-content__title">MixMyNews</h1>	

                        <p class="lead">Benvenuti in MixMyNews, un luogo digitale dove la curiosità è la nostra bussola e l'ispirazione è la nostra meta. Qui esploriamo il vasto universo delle idee, dal benessere al tempo libero, dai viaggi alla moda, con una spruzzata di motori per aggiungere un po' di adrenalina.</p> 

                        <p>Intrigare e informare sono i nostri imperativi. Navigando attraverso le pagine di questo spazio virtuale, viaggiate attraverso le meraviglie del mondo, scoprendo curiosità che solleticano la mente e stimolano la creatività. Dal più semplice dei consigli per il benessere quotidiano ai luoghi più esotici da esplorare, il nostro obiettivo è lasciarvi desiderare di sapere di più.</p>

                        <p>Immergetevi nella bellezza dei viaggi e scoprite nuove destinazioni che solleticano la vostra voglia di avventura. Esplorate le ultime tendenze della moda, immergendovi nel mondo dello stile e dell'eleganza. E per coloro che amano il brivido della velocità, non temete, abbiamo anche un angolo dedicato ai motori, dove potrete scoprire le ultime novità nel mondo dell'automobilismo e delle due ruote.</p>

                        <hr/>

                        <div class="row block-large-1-2 block-tab-full s-content__blocks">
                            <div class="column">
                                <h4>Who.</h4>
                                <p>Siamo curiosi esploratori digitali, amanti del benessere, viaggiatori instancabili, appassionati di motori e seguaci delle ultime tendenze della moda. Siamo un team pronto a condividere con voi le nostre scoperte e a incuriosirvi con ogni nuovo articolo.</p>
                            </div>

                            <div class="column">
                                <h4>When.</h4>
                                <p>Quando potrete esplorare il nostro blog? Il nostro spazio digitale è aperto 24 ore al giorno, 7 giorni su 7. Che siate mattinieri alla ricerca di ispirazione per iniziare bene la giornata o nottambuli in cerca di una lettura interessante prima di dormire, siamo qui per voi in ogni momento.</p>
                            </div>

                            <div class="column">
                                <h4>What.</h4>
                                <p>Troverete un mondo di curiosità da soddisfare, consigli per il benessere da adottare nella vostra vita quotidiana, destinazioni di viaggio da aggiungere alla vostra lista dei desideri, ultime novità nel mondo dei motori da esplorare e tendenze moda da abbracciare.</p>
                            </div>

                            <div class="column">
                                <h4>How.</h4>
                                <p>Articoli ricchi di contenuti interessanti, immagini suggestive e consigli pratici. Utilizziamo la parola scritta e visiva per trasportarvi in ​​avventure emozionanti e per farvi desiderare di scoprire di più. Siamo qui per stimolare la vostra mente e invogliarvi ad esplorare il mondo con occhi nuovi.</p>
                            </div>

                        </div>

                    </div> 

                </section>

            </div>
        </div> 
    </section> 


      </>
      
      );
    };
    
export default Post;