import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link,useParams } from 'react-router-dom';


import helpers from "../_helpers/strapi_functions.js";

const Post = () => {

  const [posts, setPost] = useState("")

  let params = useParams();
  const id = params.id;
   const articolo = params.articolo;

    useEffect(() => {
       helpers.getSingleArticle(id).then(posts=>{
      console.log(posts)
        setPost(posts)
       })
       
    }, []);

    return (
    <>
    { posts.data && ( <>
    <Helmet>
        <meta name="description" content={posts.data.attributes.Abstract[0].children[0].text} />
        <meta name="keywords" content="react, meta tags, seo" />
        <meta name="author" content="Mixmynews" />
        <meta property="og:title" content={posts.data.attributes.Titolo} />
        <meta property="og:description" content={posts.data.attributes.Abstract[0].children[0].text} />
        <meta property="og:image" content={"https://contents.mixmynews.com"+posts.data.attributes.Immagine.data.attributes.url} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:title" content={posts.data.attributes.Titolo} />
        <meta name="twitter:description" content={posts.data.attributes.Abstract[0].children[0].text} />
        <meta name="twitter:image" content={"https://contents.mixmynews.com"+posts.data.attributes.Immagine.data.attributes.url} />
        <meta name="twitter:card" content={"https://contents.mixmynews.com"+posts.data.attributes.Immagine.data.attributes.url} />
    </Helmet>
  {/* content
    ================================================== */}
  <section className="s-content s-content--single">
    <div className="row">
      <div className="column large-12">
        <article className="s-post entry format-standard">
          <div className="s-content__media">
            <div className="s-content__post-thumb">
              <img
                src={"https://contents.mixmynews.com"+posts.data.attributes.Immagine.data.attributes.url}
                srcSet={"https://contents.mixmynews.com"+posts.data.attributes.Immagine.data.attributes.url}
                sizes="(max-width: 2100px) 100vw, 2100px"
                alt=""
              />
            </div>
          </div>{" "}
          {/* end s-content__media */}
          <div className="s-content__primary">
            <h2 className="s-content__title s-content__title--post">
            {posts.data.attributes.Titolo}
            </h2>
            <ul className="s-content__post-meta">
              <li className="date">{posts.data.attributes.Data}</li>
              <li className="cat">
              { posts.data.attributes.categoria.data && 
                        (
                          posts.data.attributes.categoria.data.map(el=>(
                            <a key={el.id} href="#">{el.attributes.Nome}</a>
                                
                            ))
                        ) 
                    }
              </li>
            </ul>
            
            <p dangerouslySetInnerHTML={{__html: posts.data.attributes.Contenuto[0].children[0].text}}  />
           
        </div>
        </article>
        </div>
        </div>  
       </section>

  {/* end s-content */}
  </> )}
</>

      
      );
    };
    
export default Post;