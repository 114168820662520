
export default {
    getHomepage,
    getCategory,
    getSingleArticle,
    getListOfCollection
}



async function getHomepage(page){

    const url = process.env.REACT_APP_BACKEND + "/get/homepage/"+page

    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json, text/plain, */*'
        }
    }

    const response = await fetch(url,options )
    const responseData = await response.json()

    return responseData
}


async function getCategory(category,page){
    const url = process.env.REACT_APP_BACKEND + "/get/category"

    const req = {
        id:category,
        page:page
    }

    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(req)
    }

    const response = await fetch(url,options )
    const responseData = await response.json()
    console.log(responseData)
    return responseData
}


async function getListOfCollection(collection){
    const url = process.env.REACT_APP_BACKEND + "/get/list_collection"

    const req = {
        collection:collection
    }

    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(req)
    }

    const response = await fetch(url,options )
    const responseData = await response.json()
    console.log(responseData)
    return responseData
}

async function getSingleArticle(id){
    const url = process.env.REACT_APP_BACKEND + "/get/single_post"

    const req = {
        category:"articolis",
        id:id
    }

    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(req)
    }

    const response = await fetch(url,options )
    const responseData = await response.json()
    
    return responseData

}