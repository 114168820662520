import { useEffect, useState } from "react";
import { Link,useParams,useHistory  } from "react-router-dom";

const Post = (props) => {

    useEffect(() => {
       
      }, []);
console.log(props)
    return (
        <>

        { props.data && 
            (
              <div className="brick entry featured-grid ">
                  <div className="entry__content"></div>
                    <div className="featured-post-slide">
                      <div className="f-slide">
                        <div
                          className="f-slide__background"
                          style={{
                            backgroundImage: 'url("https://contents.mixmynews.com'+props.data[0].attributes.Immagine.data.attributes.url+'")'
                          }}
                        />
                        <div className="f-slide__overlay" />
                        <div className="f-slide__content">
                          <ul className="f-slide__meta">
                            <li>{props.data[0].attributes.Data}</li>
                            
                          </ul>
                          <h1 className="f-slide__title">
                            <Link to={"/post/"+props.data[0].attributes.id_articolo+"/"+props.data[0].attributes.serp} title="">
                              {props.data[0].attributes.Titolo}
                            </Link>
                          </h1>
                        </div>
                      </div>{" "}
                    {/* f-slide */}
                  </div>{" "}
             </div>
            ) 
        }


      </>
      
      );
    };
    
export default Post;