import { useEffect, useState } from "react";
import { Link,useParams } from 'react-router-dom';

import helpers from "../_helpers/strapi_functions.js";

import Pagination from '../pagination/pagination.js';
import Masonry from '@mui/lab/Masonry';
import Post from '../homepage/post.js';

const Category = () => {

  const [posts, setPosts] = useState("")
  const [page, setPage] = useState(1)

  let params = useParams();
  const id = params.id;
  const nome_categoria = params.nome;

    useEffect(() => {
       helpers.getCategory(id,page).then(posts=>{
        setPosts(posts)
       })
       
      }, [params.id,page]);

    return (
        <section className="s-bricks">
            <div className="masonry">
            <div className="bricks-wrapper h-group">
          
            
            {posts.data ? (<div>
                     
                      <Masonry columns={4} spacing={2}>
                           
                            
                                {
                                    posts.data.map(el=>(
                                      <Post {... el} key={el.attributes.id_articolo}/>
                                    ))
                                }
                      </Masonry>
                      </div>
                    ):(<></>)} 

                 
                    
           
        
            </div>
        </div>
        {posts.meta && (
          <Pagination 
            totalPages={posts.meta.pagination.pageCount} 
            currentPage = {page}
            onChangePage={(page) => {setPage(page);document.body.scrollTop = 0;document.documentElement.scrollTop = 0; } }
          />
        )}
      </section>
      );
    };
    
export default Category;