import { useEffect, useState } from "react";


const Pagination = (props) => {
    
    
    const [page, setPage] = useState(1)
    const [normalizePages,setNormalizePages] = useState()

    useEffect(() => {
        console.log(props.currentPage,page)
        console.log(props.currentPage == page)
        
       }, [page]);
    

    return (
        <>
            <div className="row">
                <div className="column large-12">
                <nav className="pgn">
                    <ul>


                    {props.currentPage && (
                        <>
                        {props.currentPage == 1  ? (
                           <li>
                             <button className="pgn__prev" onClick={()=>{return null}}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                            >
                                <path d="M12.707 17.293L8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                            </svg>
                            </button>
                           </li>
                        ):(
                            <li>
                            <button className="pgn__prev" onClick={()=>{props.onChangePage((page -1 ));setPage(page - 1)}}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                            >
                                <path d="M12.707 17.293L8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                            </svg>
                            </button>
                        </li>
                        )}
                        </>    
                   )}


                   
                    { props.totalPages ? (
                        [...Array(props.totalPages)].map((obj,index) => { return (<>
                            {props.currentPage == (index +1) ? (
                                <li style={{backgroundColor: 'rgb(227, 217, 133)'}}>
                                    <a className="pgn__num" onClick={()=>{props.onChangePage(index + 1);setPage(index+1)}}>
                                    {index + 1}
                                    </a>
                                </li>
                            ) : (
                                <li>
                                    <a className="pgn__num" onClick={()=>{props.onChangePage(index + 1);setPage(index+1)}}>
                                    {index + 1}
                                    </a>
                                </li>
                            )}
                            
                        </>) })

                        ) : null
                    }
                   
                   
                   {props.totalPages && (
                        <>
                        {props.totalPages == page  ? (
                            <li>
                                 <button className="pgn__next" aria-disabled={true}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M11.293 17.293l1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z" />
                                </svg>
                                </button>
                            </li>
                        ):(
                            <li>
                                <button className="pgn__next"  onClick={()=>{props.onChangePage((page + 1));setPage(page + 1)}}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M11.293 17.293l1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z" />
                                </svg>
                                </button>
                            </li>
                        )}
                        </>    
                   )}
                   
                    </ul>
                </nav>{" "}
                {/* end pgn */}
                </div>{" "}
                {/* end column */}
            </div>{" "}
            {/* end row */}
            </>
      );
    };
    
export default Pagination;