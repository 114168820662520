import { useEffect, useState } from "react";
import { Link,useParams,useHistory  } from "react-router-dom";

import helpers from "../_helpers/strapi_functions.js";
import logo from '../../images/logo.png'
const Header = () => {

  const [categorie, setCategorie] = useState(null)
  useEffect(() => {
    helpers.getListOfCollection("categories").then(categories=>{
      console.log(categories)
      setCategorie(categories)
    })
    
   }, []);

    return (
        <>
        <header className="s-header">
          <div className="row s-header__content">
            <div className="s-header__logo">
            <Link to="/" title="Homepage" className="logo">
                <img src={logo} alt="Homepage" />
              </Link>
            </div>
            <nav className="s-header__nav-wrap">
              <h2 className="s-header__nav-heading h6">Site Navigation</h2>
              <ul className="s-header__nav">
                <li className="current">
                  <Link to="/" title="Homepage">
                    Home
                  </Link>
                </li>
                <li className="has-children">
                  <a href="#0" title="">
                    Categories
                  </a>
                  <ul className="sub-menu">
                  { categorie && 
                  
                        (
                          categorie.data.map(el=>(
                            
                            <li key={el.attributes.id_categoria}>
                              <Link to={"/category/"+el.id+"/"+el.attributes.Nome}>{el.attributes.Nome}</Link>
                          
                            </li>
                             
                                
                            ))
                        ) 
                    }
                   
                  </ul>
                </li>
                
                <li>
                  <Link to="/about" title="About">
                    About
                  </Link>
                </li>
                <li>
                <Link to="/contact" title="Contact">
                    Contact
                  </Link>
                </li>
              </ul>{" "}
              {/* end header__nav */}
              <a
                href="#0"
                title="Close Menu"
                className="s-header__overlay-close close-mobile-menu"
              >
                Close
              </a>
            </nav>{" "}
            {/* end header__nav-wrap */}
            <a className="s-header__toggle-menu" href="#0" title="Menu">
              <span>Menu</span>
            </a>
            <div className="s-header__search">
              <form
                role="search"
                method="get"
                className="s-header__search-form"
                action="#"
              >
                <label>
                  <span className="hide-content">Search for:</span>
                  <input
                    type="search"
                    className="s-header__search-field"
                    placeholder="Type Your Keywords"
                    defaultValue=""
                    name="s"
                    title="Search for:"
                    autoComplete="off"
                  />
                </label>
                <input
                  type="submit"
                  className="s-header__search-submit"
                  defaultValue="Search"
                />
              </form>
              <a href="#0" title="Close Search" className="s-header__overlay-close">
                Close
              </a>
            </div>{" "}
            {/* end search wrap */}
            <a className="s-header__search-trigger" href="#">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M10 18a7.952 7.952 0 004.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0018 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z" />
              </svg>
            </a>
          </div>{" "}
          {/* end s-header__content */}
        </header>{" "}
        {/* end header */}
      </>
      );
    };
    
export default Header;