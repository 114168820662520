import { useEffect, useState } from "react";

import helpers from "../_helpers/strapi_functions.js";

import Pagination from '../pagination/pagination.js';
import SpecialPost from './special_post';
import Post from './post.js';
import Masonry from '@mui/lab/Masonry';


const Homepage = () => {

  const [posts, setPosts] = useState("")
  const [page, setPage] = useState(1)

    useEffect(() => {
       helpers.getHomepage(page).then(posts=>{
       
        setPosts(posts)
       })
       
      }, [page]);

    return (
        <section className="s-bricks">
            <div className="masonry">
              <div className="bricks-wrapper h-group">
                
            
                    {posts.data ? (<div>
                      <SpecialPost {... posts}/>
                      <Masonry columns={4} spacing={2}>
                           
                            
                                {
                                    posts.data.slice(1).map(el=>(
                                      <Post {... el} key={el.attributes.id_articolo}/>
                                    ))
                                }
                      </Masonry>
                      </div>
                    ):(<></>)}   
                </div>  
        </div>
        
        {posts.meta && (
          <Pagination 
            totalPages={posts.meta.pagination.pageCount} 
            currentPage = {page}
            onChangePage={(page) => {setPage(page);document.body.scrollTop = 0;document.documentElement.scrollTop = 0; } }
          />
        )}
      </section>
      );
    };
    
export default Homepage;